import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'antd';

import { CustomTable } from './components/Table';
import { UserFilter } from '../../components/filter/UserFilter';
import styles from './styles.module.scss';

export const PermissionsPage = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [title, setTitle] = useState<string>();
  // const canCreate = JSON.parse(localStorage.getItem('user'))?.role?.permissions[type]?.includes('can_create');

  const onNavigateToCreateUser = () => {
    history.push('/permissions/users/create');
  };

  const onNavigateToCreateRole = () => {
    history.push('/permissions/roles/create');
  };

  // const setPageTitle = useCallback(() => {
  //   switch (type) {
  //     case 'users':
  //       setTitle('List of users');
  //       break;
  //     case 'roles':
  //       setTitle('List of roles');
  //       break;
  //     default:
  //       return;
  //   }
  // }, [type]);

  // useEffect(() => {
  //   setPageTitle();
  // }, [setPageTitle]);

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        {type === 'users' && (
          <div>
            <UserFilter />
          </div>
        )}
      </div>
      <div>
        {/* {type === 'users' && canCreate && (
          <Button type="primary" onClick={type === 'users' ? onNavigateToCreateUser : onNavigateToCreateRole}>
            + Create new {type === 'users' ? 'user' : 'role'}
          </Button>
        )} */}
      </div>
      <div style={{ marginTop: '24px' }}>
        <CustomTable pageName={type} />
      </div>
    </div>
  );
};
