import { FC } from 'react';
import { Route } from 'react-router-dom';

import { ProtectedLayout } from '../components/protectedLayout/protectedLayout';
import { Profile } from '../models';
import { AbcAnalysisPage } from '../pages/abcAnalysis/AbcAnalysisPage';
// import { BasketAnalysisPage } from '../pages/basketAnalysis/BasketAnalysisPage';
import { BuyingBehaviorPage } from '../pages/buyingBehavior/BuyingBehaviorPage';
import { CoExistencePage } from '../pages/coExistence/CoExistencePage';
import { CoExistenceTrendsPage } from '../pages/coExistenceTrends/CoExistenceTrendsPage';
import { ComparisonPage } from '../pages/comparison/ComparisonPage';
import { ComparisonProductPage } from '../pages/comparisonProduct/ComparisonProductPage';
import { ComparisonSharePage } from '../pages/comparisonShare/ComparisonSharePage';
import { MarketSharePage } from '../pages/marketShare/MarketSharePage';

import { OutOfStockPage } from '../pages/outOfStock/OutOfStockPage';
// import { CreateRole } from '../pages/permissions/CreateRole/CreateRole';
// import { CreateUser } from '../pages/permissions/CreateUser/CreateUser';
import { PermissionsPage } from '../pages/permissions/PermissionsPage';
import { PriceElasticityPage } from '../pages/priceElasticity/PriceElasticityPage';
import { SalesStatisticsPage } from '../pages/salesStatistics/SalesStatisticsPage';
import { TrendsPage } from '../pages/trends/TrendsPage';
import { TrendsSharePage } from '../pages/trendsShare/TrendsSharePage';
import { DetailedLogsPage } from '../pages/userLogs/DetailedLogsPage';
import { UserLogsPage } from '../pages/userLogs/UserLogsPage';
import { VpoSegmentationPage } from '../pages/vpoSegmentation/VpoSegmentationPage';
import { VpoTrendsPage } from '../pages/vpoTrends/VpoTrendsPage';
import { StoresPage } from '../pages/stores/StoresPage';
import { StoreStatisticsPage } from '../pages/storeStatistics/StoreStatisticsPage';
import { CoExistenceCombinationsPage } from '../pages/coExistenceCombinations/CoExistenceCombinationsPage';
import { DownloadsPage } from '../pages/downloads/DownloadsPage';
// import { CreateRoleNew } from '../pages/permissions/CreateRole/CreateRoleNew';

import { SalesStatisticsReportsPage } from '../pages/salesStatisticsReports/SalesStatisticsReportsPage';
import { ComparisonShareReportsPage } from '../pages/comparisonShareReports/ComparisonSharePage';
import { MarketSharePage as MarketShareReportsPage } from '../pages/marketShareReports/MarketSharePage';
import { TrendsSharePage as TrendsShareReportsPage } from '../pages/trendsShareReports/TrendsSharePage';
type AppRouterProps = {
  profile: Profile;
  logout?: () => void;
};

export const AppRouter: FC<AppRouterProps> = ({ profile, logout }) => {
  return (
    <ProtectedLayout user={profile} logout={logout}>
      <Route exact path="/sales/:type" component={SalesStatisticsPage} />
      <Route exact path="/trends/:type/:tab" component={TrendsPage} />
      <Route exact path="/comparison/:type/:tab" component={ComparisonPage} />

      <Route exact path="/permissions/:type" component={PermissionsPage} />
      <Route exact path="/permissions/users/create" component={PermissionsPage} />
      <Route path="/permissions/users/:userId/edit" component={PermissionsPage} />
      <Route exact path="/permissions/roles/create" component={PermissionsPage} />
      <Route path="/permissions/roles/:roleId/edit" component={PermissionsPage} />
      <Route exact path="/users-logs" component={UserLogsPage} />
      <Route exact path="/users-logs/:userId" component={DetailedLogsPage} />

      <Route exact path="/abc-analysis/:tab" component={AbcAnalysisPage} />
      <Route exact path="/market-share/:type/" component={MarketSharePage} />
      <Route exact path="/trends-share/:type" component={TrendsSharePage} />
      <Route exact path="/out-of-stock" component={OutOfStockPage} />

      <Route exact path="/buying-behavior" component={BuyingBehaviorPage} />
      <Route exact path="/price-elasticity" component={PriceElasticityPage} />
      <Route exact path="/comparison-share/:type" component={ComparisonSharePage} />
      <Route exact path="/comparison-product" component={ComparisonProductPage} />
      <Route exact path="/co-existence" component={CoExistencePage} />
      <Route exact path="/co-existence/combination/:type/:number_of_products" component={CoExistenceCombinationsPage} />
      <Route exact path="/co-existence-trends" component={CoExistenceTrendsPage} />
      <Route exact path="/vpo-segmentation" component={VpoSegmentationPage} />
      <Route exact path="/vpo-trends" component={VpoTrendsPage} />
      {/* <Route exact path="/basket-analysis" component={BasketAnalysisPage} /> */}
      <Route exact path="/stores" component={StoresPage} />
      <Route exact path="/stores-statistics/:type/:typeOfPage" component={StoreStatisticsPage} />
      <Route exact path="/downloads" component={DownloadsPage} />

      <Route exact path="/sales/reports/:type" component={SalesStatisticsReportsPage} />
      <Route exact path="/market-share/reports/:type/" component={MarketShareReportsPage} />
      <Route exact path="/trends-share/reports/:type" component={TrendsShareReportsPage} />
      <Route exact path="/comparison-share/reports/:type" component={ComparisonShareReportsPage} />
    </ProtectedLayout>
  );
};
