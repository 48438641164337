import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { notification, PaginationProps, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import qs from 'qs';
import type { ResizeCallbackData } from 'react-resizable';

import SalesStatisticsService from '../../../services/salesStatistics';
import { useColumns } from './useColumnsNew';
import { ResizableColumnTitle } from '../../../components/ResizableColumnTitle/ResizableColumnTitle';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  pageName: string;
  rule?: any;
  typeOfCompany?: string;
  isExtrapolation?: boolean;
  hiddenColumns?: any;
  isMobile?: boolean;
};

export const SalesStatisticsReportsTable = ({
  pageName,
  rule,
  typeOfCompany,
  isExtrapolation,
  hiddenColumns,
  isMobile,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  // const queryClient = useQueryClient();
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;
  const [totalInfo, setTotalInfo] = useState<Record<string, string | number>>({});
  const regions = rule?.regions?.join(',');
  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(
    ['salesStatisticsTable', pageName, isExtrapolation, regions, location.search],
    ({ signal }) => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
      switch (pageName) {
        case 'company':
          return isExtrapolation
            ? SalesStatisticsService.getExtrapolationSalesStatisticsByCompany(
                {
                  months: params?.months || defaultDate,
                  limit: +params?.limit || 10,
                  city: params?.city || regions || '24',
                  ...params,
                },
                { signal }
              )
            : SalesStatisticsService.getSalesStatisticsReportsByCompany(
                {
                  period_1_after: params?.period_after,
                  period_1_before: params?.period_before,
                  size: +params?.limit || 10,
                  city_id: params?.city || regions || '24',
                  common_stores: params.active_stores,
                  subcategory_id: params?.subcategory,
                  ...params,
                },
                { signal }
              );

        case 'brand':
          return isExtrapolation
            ? SalesStatisticsService.getExtrapolationSalesStatisticsByBrand(
                {
                  months: params?.months || defaultDate,
                  limit: +params?.limit || 10,
                  city: params?.city || regions || '24',
                  ...params,
                },
                { signal }
              )
            : SalesStatisticsService.getSalesStatisticsReportsByBrand(
                {
                  period_1_after: params?.period_after,
                  period_1_before: params?.period_before,
                  size: +params?.limit || 10,
                  city_id: params?.city || regions || '24',
                  common_stores: params.active_stores,
                  subcategory_id: params?.subcategory,
                  ...params,
                },
                { signal }
              );

        case 'barcode':
          return isExtrapolation
            ? SalesStatisticsService.getExtrapolationSalesStatisticsByBarcode(
                {
                  months: params?.months || defaultDate,
                  limit: +params?.limit || 10,
                  city: params?.city || regions || '24',
                  ...params,
                },
                { signal }
              )
            : SalesStatisticsService.getSalesStatisticsReportsByBarcode(
                {
                  period_1_after: params?.period_after,
                  period_1_before: params?.period_before,
                  size: +params?.limit || 10,
                  city_id: params?.city || regions || '24',
                  common_stores: params.active_stores,
                  subcategory_id: params?.subcategory,
                  ...params,
                },
                { signal }
              );
        default:
          return;
      }
    }
  );

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trendsTable', { active: true });
  // }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      const dataWithKey = data?.data?.results.map((item, index) => ({
        ...item,
        key: `${item?.local_company + index}`,
      }));

      setTableData(dataWithKey);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional[0]);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        if (error.response?.data[0]) {
          openNotification('cities can`t be extrapolated');
        } else {
          openNotification(error?.response?.data?.['months']?.[0]);
        }
        setTableData([]);
        setTotal(0);
        setTotalInfo({});
      }
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotal(0);
        setTotalInfo({});
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
        setTotalInfo({});
      }
    }
  }, [data, error]);

  const columnsParams = useMemo(
    () => ({ pageName, isExtrapolation, hiddenColumns, isMobile }),
    [pageName, isExtrapolation, hiddenColumns, isMobile]
  );
  const columnsMemo = useColumns(columnsParams);

  const [columns, setColumns] = useState(columnsMemo);

  const handleResize: Function =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...columns];
      if (size.width >= columns[index].minWidth) {
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        setColumns(newColumns);
      }
    };

  const isColumnsChanged = useCallback(() => {
    if (columnsParams) {
      return true;
    }
  }, [columnsParams]);

  const memoizedColumns = columns.map((col, index) => ({
    ...col,
    hidden: col['hidden'],
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index) as React.ReactEventHandler<any>,
    }),
  }));

  useEffect(() => {
    if (isColumnsChanged) {
      setColumns(columnsMemo);
    }
  }, [isColumnsChanged]);

  return (
    <Table
      columns={memoizedColumns.filter((item) => !item['hidden'])}
      dataSource={tableData}
      loading={isFetching}
      className={styles.ant_table}
      sticky
      rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
      pagination={{
        pageSize: limit,
        defaultPageSize: 10,
        total: total,
        onChange: onPageChange,
        current: page,
        defaultCurrent: 1,
      }}
      scroll={{ x: '100%' }}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              {totalInfo?.value && (
                <>
                  {columns
                    .filter((item: any) => !item['hidden'])
                    ?.map((item: any, index: number) => {
                      switch (item.title) {
                        case 'Value kzt':
                          return (
                            <Table.Summary.Cell
                              index={index}
                              key={`${item.title + index}`}
                              className={styles.align_right}
                            >
                              <Text className={styles.total_bold}>
                                {`${totalInfo?.value}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                              </Text>
                            </Table.Summary.Cell>
                          );
                        case 'Items':
                          return (
                            <Table.Summary.Cell
                              index={index}
                              key={`${item.title + index}`}
                              className={styles.align_right}
                            >
                              <Text className={styles.total_bold}>
                                {`${totalInfo?.items || ''}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                              </Text>
                            </Table.Summary.Cell>
                          );
                        case 'Volume':
                          return (
                            <Table.Summary.Cell
                              index={index}
                              key={`${item.title + index}`}
                              className={styles.align_right}
                            >
                              <Text className={styles.total_bold}>
                                {`${totalInfo.volume}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                              </Text>
                            </Table.Summary.Cell>
                          );
                        case 'Avg. price':
                          return (
                            <Table.Summary.Cell
                              index={index}
                              key={`${item.title + index}`}
                              className={styles.align_right}
                            >
                              <Text className={styles.total_bold}>{totalInfo?.avg_price}</Text>
                            </Table.Summary.Cell>
                          );
                        case 'Avg. price per litre':
                          return (
                            <Table.Summary.Cell
                              index={index}
                              key={`${item.title + index}`}
                              className={styles.align_right}
                            >
                              <Text className={styles.total_bold}>
                                <Text className={styles.total_bold}>{totalInfo.avg_price_per_litre}</Text>
                              </Text>
                            </Table.Summary.Cell>
                          );
                        case 'Stores':
                          return (
                            <Table.Summary.Cell
                              index={index}
                              key={`${item.title + index}`}
                              className={styles.align_right}
                            >
                              <Text className={styles.total_bold}>
                                {`${totalInfo.stores ?? '--'}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                              </Text>
                            </Table.Summary.Cell>
                          );
                        default:
                          return <Table.Summary.Cell index={index} key={`${item.title + index}`} />;
                      }
                    })}
                </>
              )}
            </Table.Summary.Row>
          </>
        );
      }}
      components={{
        header: {
          cell: ResizableColumnTitle,
        },
      }}
      bordered
      size="middle"
    />
  );
};
