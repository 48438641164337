import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { useMediaQuery } from 'react-responsive';
import { Button, Drawer, Dropdown, Layout, List, Menu, Space, Typography } from 'antd';
import { MenuOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';

import { helpText } from '../../utils/helperText';
import { ChangePassword } from '../../pages/login/components/ChangePassword';
import { Items, UserMenuItems } from './headerLinks';
import { ItemsMobile } from './HeaderLinkMobile';
import FilterService from '../../services/filter';
import { DownloadsDrawer } from '../DownloadsDrawer/DownloadsDrawer';
import { Logo } from '../Logo';
import './navheader.css';

const { Header } = Layout;
const { Text } = Typography;

type Props = {
  user?: any;
  logout?: () => void;
};

const mappedTitle = {
  '/sales': 'Sales statistics',
  '/trends/company': 'Trends',
  '/trends/brand': 'Trends',
  '/trends/barcode': 'Trends',
  '/comparison/company': 'Comparison',
  '/comparison/brand': 'Comparison',
  '/comparison/barcode': 'Comparison',
  '/market-share': 'Market share',
  '/trends-share': 'Trends share',
  '/comparison-share': 'Comparison share',
  '/co-existence': 'Co-Existance',
  '/abc-analysis': 'ABC analysis',
  '/stores': 'Stores',
  '/out-of-stock': 'By barcode',
  '/buying-behavior': 'Buying behavior',
  '/price-elasticity': 'Price elasticity',
  '/comparison-product': 'Comparison product',
  '/co-existence-trends': 'CoExistence trends',
  '/vpo-segmentation': 'VPO - Segmentation',
  '/vpo-trends': 'VPO - Segmentation Trends',
};

const routesConfig = [
  { pattern: /^\/sales/, key: '/sales' },
  { pattern: /^\/market-share/, key: '/market-share' },
  { pattern: /^\/trends-share/, key: '/trends-share' },
  { pattern: /^\/comparison-share/, key: '/comparison-share' },
  { pattern: /^\/comparison-product/, key: '/comparison-product' },
  { pattern: /^\/abc-analysis/, key: '/abc-analysis' },
  { pattern: /^\/trends(\/(value|item|volume))?/, key: (match) => match[0] },
  { pattern: /^\/comparison(\/(value|item|volume))?/, key: (match) => match[0] },
];

export const NavHeader = ({ user, logout }: Props) => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [current, setCurrent] = useState<string | any>('sales');
  const [openModal, setOpenModal] = useState(false);
  const [isOpenDonwloads, setIsOpenDonwloads] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState<string | number>(
    Number(localStorage.getItem('category')) || Number(user?.company?.categories[0]?.id)
  );
  const [categories, setCategories] = useState<Record<string, string>[]>([]);
  const isPathIncludesStoreStatistics = location.pathname.includes('/stores-statistics');

  const onOpenFilter = () => {
    setOpenModal(true);
  };

  const hide = () => {
    setOpenModal(false);
  };

  const openDownloads = () => {
    setIsOpenDonwloads(true);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const onOpenInfoModal = () => {
    setOpenInfoModal(true);
  };

  const closeInfoModal = () => {
    setOpenInfoModal(false);
  };

  const handleLogOut = useCallback(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    FilterService.getNewOptionsDev('category').then((res) => setCategories(res.data));

    const pathname = location.pathname;
    const matchedRoute = routesConfig.find((route) => route.pattern.test(pathname));
    if (matchedRoute) {
      const match = pathname.match(matchedRoute.pattern);
      const key = typeof matchedRoute.key === 'function' ? matchedRoute.key(match) : matchedRoute.key;
      setCurrent(key);
    } else {
      setCurrent(pathname);
    }

    return () => setCurrent('sales');
  }, [location.pathname]);

  return (
    <Header className="header">
      {isTabletOrMobile ? (
        <>
          <Button
            type="text"
            onClick={onOpenFilter}
            style={{ marginTop: '8px', marginLeft: '-8px' }}
            icon={<MenuOutlined />}
            shape="circle"
          />
          <div style={{ marginTop: '-8px' }}>
            {isPathIncludesStoreStatistics ? 'Stores statistics' : mappedTitle[current] || current}
          </div>

          <div />
          <Drawer title="" placement="left" onClose={hide} open={openModal} closable={false} width={'320'}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
              <div>
                <span>
                  <UserOutlined /> {user?.email || 'user'}
                </span>
                <Menu
                  theme="light"
                  selectedKeys={[current]}
                  mode="inline"
                  items={ItemsMobile(user?.role?.permissions)}
                  inlineIndent={8}
                  onClick={hide}
                />
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <div className="content">
            <div className="logo">
              <div className="logo-svg">
                <Logo />
              </div>
            </div>
            <Menu
              style={{
                height: '50px',
                backgroundColor: '#F6F6F6',
              }}
              theme="light"
              selectedKeys={[current]}
              mode="horizontal"
              items={Items(user?.role?.permissions, user?.company?.id, current)}
            />
          </div>

          <div className="info">
            <div style={{ cursor: 'pointer' }}>
              <Space>
                <Button
                  type="text"
                  onClick={onOpenInfoModal}
                  style={{ marginRight: '8px' }}
                  icon={<QuestionCircleOutlined />}
                  shape="circle"
                />

                <Dropdown
                  menu={UserMenuItems(
                    handleLogOut,
                    onOpenFilter,
                    categories,
                    defaultCategory,
                    setDefaultCategory,
                    openDownloads,
                    user
                  )}
                >
                  <span>{user?.email || 'user'}</span>
                </Dropdown>
              </Space>
            </div>
          </div>
          <Drawer title="Change password" placement="right" onClose={hide} open={openModal}>
            <ChangePassword onFilterChange={hide} />
          </Drawer>
          <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDonwloads}>
            <DownloadsDrawer open={isOpenDonwloads} />
          </Drawer>
          <Drawer title="Термины" placement="right" onClose={closeInfoModal} open={openInfoModal}>
            <List
              size="small"
              dataSource={helpText}
              renderItem={(item) => (
                <List.Item>
                  <Text strong>{item.title}</Text> - {item.info}
                </List.Item>
              )}
            />
          </Drawer>
        </>
      )}
    </Header>
  );
};
