import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';

export const ItemsMobile = (permissions: any): MenuProps['items'] => [
  {
    label: 'Sales Report',
    key: 'sales',
    children: [
      permissions?.includes('sales_statistics') && {
        type: 'group',
        label: 'Sales statistics',
        children: [
          {
            label: <Link to="/sales/company">By company</Link>,
            key: '/sales/company',
          },
          {
            label: <Link to="/sales/brand">By brand</Link>,
            key: '/sales/brand',
          },
          {
            label: <Link to="/sales/barcode">By barcode</Link>,
            key: '/sales/barcode',
          },
        ],
      },
      permissions?.includes('trends') && {
        type: 'group',
        label: 'Trends',
        children: [
          {
            label: <Link to="/trends/company/value">By company</Link>,
            key: '/trends/company',
          },
          {
            label: <Link to="/trends/brand/value">By brand</Link>,
            key: '/trends/brand',
          },
          {
            label: <Link to="/trends/barcode/value">By barcode</Link>,
            key: '/trends/barcode',
          },
        ],
      },
      permissions?.includes('comparison') && {
        type: 'group',
        label: 'Сomparison',
        children: [
          {
            label: <Link to="/comparison/company/value">By company</Link>,
            key: '/comparison/company',
          },
          {
            label: <Link to="/comparison/brand/value">By brand</Link>,
            key: '/comparison/brand',
          },
          {
            label: <Link to="/comparison/barcode/value">By barcode</Link>,
            key: '/comparison/barcode',
          },
        ],
      },
    ],
  },
  {
    label: 'Share by cities',
    key: 'trends',
    children: [
      permissions?.includes('market_share') && {
        type: 'group',
        label: 'Market',
        children: [
          {
            label: <Link to="/market-share/company">By company</Link>,
            key: '/market-share/company',
          },
          {
            label: <Link to="/market-share/brand">By brand</Link>,
            key: '/market-share/brand',
          },
          {
            label: <Link to="/market-share/barcode">By barcode</Link>,
            key: '/market-share/barcode',
          },
        ],
      },
      permissions?.includes('share_trends') && {
        type: 'group',
        label: 'Trends',
        children: [
          {
            label: <Link to="/trends-share/company">By company</Link>,
            key: '/trends-share/company',
          },
          {
            label: <Link to="/trends-share/brand">By brand</Link>,
            key: '/trends-share/brand',
          },
          {
            label: <Link to="/trends-share/barcode">By barcode</Link>,
            key: '/trends-share/barcode',
          },
        ],
      },
      permissions?.includes('share_comparison') && {
        type: 'group',
        label: 'Comparison',
        children: [
          {
            label: <Link to="/comparison-share/company">By company</Link>,
            key: '/comparison-share/company',
          },
          {
            label: <Link to="/comparison-share/brand">By brand</Link>,
            key: '/comparison-share/brand',
          },
          {
            label: <Link to="/comparison-share/barcode">By barcode</Link>,
            key: '/comparison-share/barcode',
          },
        ],
      },
    ],
  },
  permissions?.includes('sales_by_stores') && {
    label: <Link to="/stores">Stores</Link>,
    key: '/stores',
  },
  {
    label: <Link to="/downloads">Downloads</Link>,
    key: '/downloads',
  },
  {
    label: 'Analytics',
    key: 'analytics',
    children: [
      {
        type: 'group',
        label: '',
        children: [
          permissions?.includes('abc_analysis') && {
            label: <Link to={`/abc-analysis/value`}>ABC analysis</Link>,
            key: `/abc-analysis`,
          },
          permissions?.includes('out_of_stock') && {
            label: <Link to="/out-of-stock">Out of stock</Link>,
            key: '/out-of-stock',
          },
          permissions?.includes('buying_behavior') && {
            label: <Link to="/buying-behavior">Buying behavior</Link>,
            key: '/buying-behavior',
          },
          permissions?.includes('price_elasticity') && {
            label: <Link to="/price-elasticity">Price elasticity</Link>,
            key: '/price-elasticity',
          },
          permissions?.includes('product_comparison') && {
            label: <Link to="/comparison-product">Sell in / Sell out Prices</Link>,
            key: '/comparison-product',
          },
          permissions?.includes('coexistence') && {
            label: <Link to="/co-existence">Co-Existence</Link>,
            key: '/co-existence',
          },
          permissions?.includes('coexistence_trends') && {
            label: <Link to="/co-existence-trends">Co-Existence Trends</Link>,
            key: '/co-existence-trends',
          },
          permissions?.includes('vpo_segmentation') && {
            label: <Link to="/vpo-segmentation">VPO Segmentation</Link>,
            key: '/vpo-segmentation',
          },
          permissions?.includes('vpo_trend') && {
            label: <Link to="/vpo-trends">VPO Segmentation Trends</Link>,
            key: '/vpo-trends',
          },
          // permissions?.basket_analysis?.includes('can_read') && {
          //   label: <Link to="/basket-analysis">Basket Analysis</Link>,
          //   key: '/basket-analysis',
          // },
        ],
      },
    ],
  },
  // (permissions?.users?.includes('can_read') ||
  //   permissions?.roles?.includes('can_read') ||
  //   permissions?.journal?.includes('can_read')) && {
  //   label: 'Manage',
  //   key: 'permissions',
  //   children: [
  //     permissions?.users?.includes('can_read') && {
  //       label: <Link to="/permissions/users">Users</Link>,
  //       key: '/permissions/users',
  //     },
  //     permissions?.roles?.includes('can_read') && {
  //       label: <Link to="/permissions/roles">Roles</Link>,
  //       key: '/permissions/roles',
  //     },
  //     permissions?.journal?.includes('can_read') && {
  //       label: <Link to="/users-logs">Users logs</Link>,
  //       key: '/users-logs',
  //     },
  //   ],
  // },
];
